<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Amortisasi Biaya</strong>
        <a-button-group class="ml-2">
          <a-button class="btn btn-outline-danger" @click="back">Back</a-button>
          <!-- :disabled="rowData.length === 0" -->
          <a-button class="btn btn-outline-primary" @click="handleSubmit"
            >Simpan</a-button
          >
          <a-button
            v-if="action === 'update'"
            class="btn btn-outline-danger"
            @click="resetForm(true)"
            >Cancel Update</a-button
          >
        </a-button-group>
      </template>
      <div class="row pl-1 pr-1 pt-1 pb-1">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label>Nama</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input placeholder="Masukan Nama" v-model="input.nama" style="width: 100%;"></a-input>
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Tgl Perolehan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-date-picker
                style="width: 125px;"
                v-model="input.tgl_perolehan"
                :allowClear="false"
                format="DD-MM-YYYY"
              />
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Harga Perolehan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <cleave
            ref="harga_perolehan"
            :options="{ numeral: true }"
            class="ant-input mr-1"
            :raw="true"
            style="text-align: right; width: 250px"
            v-model="input.harga_perolehan"
            placeholder="Masukan Harga Perolehan"
          />
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Lama Penyusutan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <cleave
            ref="lama_penyusutan"
            :options="{ numeral: true }"
            class="ant-input mr-1"
            :raw="true"
            style="text-align: right; width: 250px"
            v-model="input.lama_penyusutan"
            placeholder="Masukan Lama Penyusutan (Bulan)"
          />
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Nilai Residu</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <cleave
            ref="nilai_residu"
            :options="{ numeral: true }"
            class="ant-input mr-1"
            :raw="true"
            style="text-align: right; width: 250px"
            v-model="input.nilai_residu"
            placeholder="Masukan Nilai Residu"
          />
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Catatan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input placeholder="Masukan Catatan" v-model="input.catatan" style="width: 100%;"></a-input>
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Rekening Kas Bank</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <v-select
              ref="rekening_kas_bank"
              placeholder="Ketikan Rekening Kas Bank"
              class="style-chooser"
              label="label"
              style="width: 100%;"
              v-model="input.rekening_kas_bank"
              :options="masterRekening"
              :reduce="(tes) => tes.kode"/>
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Rekening Aktiva</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <v-select
              ref="rekening_aktiva"
              placeholder="Ketikan Rekening Aktiva"
              class="style-chooser"
              label="label"
              style="width: 100%;"
              v-model="input.rekening_aktiva"
              :options="masterRekening"
              :reduce="(tes) => tes.kode"/>
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Rekening Amortisasi</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <v-select
              ref="rekening_amortisasi"
              placeholder="Ketikan Rekening Amortisasi"
              class="style-chooser"
              label="label"
              style="width: 100%;"
              v-model="input.rekening_amortisasi"
              :options="masterRekening"
              :reduce="(tes) => tes.kode" />
        </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Rekening Biaya</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
            <v-select
              ref="rekening_biaya"
              placeholder="Ketikan Rekening Biaya"
              class="style-chooser"
              label="label"
              style="width: 100%;"
              v-model="input.rekening_biaya"
              :options="masterRekening"
              :reduce="(tes) => tes.kode" />
        </div>
          </div>
        </div>
      </div>
    </a-card>
    <!-- <a-button
              ref="addBtn"
              class="ml-2 btn btn-outline-primary"
              @click="addNullData"
            >
              Tambah
            </a-button> -->
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import Cleave from 'vue-cleave-component'

import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    // vueCustomScrollbar,
    cleave: Cleave,
  },
  created() {
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.gridOptions1 = {}
    this.gridApi1 = this.gridOptions1.api
    this.gridColumnApi1 = this.gridOptions1.columnApi
    this.getAllData()
    // this.getMaster()
  },
  // watch: {
  //   'rowData'(o, n) {
  //     console.log('o', o.length - 1)
  //     console.log('n', n)
  //   },
  // },
  data() {
    return {
      action: 'create',
      title: 'Create Amortisasi Biaya',
      tgl1: moment(),
      editdata: JSON.parse(localStorage.getItem('projectatransaksiamortisasibiaya')),
      masterRekening: [],
      input: {
        nama: '',
        tgl_perolehan: moment(),
        harga_perolehan: '',
        lama_penyusutan: '',
        nilai_residu: '',
        catatan: '',
        rekening_kas_bank: '',
        rekening_aktiva: '',
        rekening_amortisasi: '',
        rekening_biaya: '',
      },

      componentKey: 0,
    }
  },
  methods: {
    moment,
    filterDate(date, dateString) {
      this.tgl1 = date
      this.getAllData()
    },
    async getAllData() {
      var res = await lou.customUrlGet2(
        'general/list_rekening',
      )
      if (res) {
        this.masterRekening = []
        res.data.forEach(element => {
          var d = {
            ...element,
            label: element.kode + ' - ' + element.keterangan,
          }
          this.masterRekening.push(d)
        })
        if (
          this.editdata !== null &&
          this.editdata.id !== null &&
          this.editdata.id !== ''
        ) {
          this.action = 'update'
          this.title = 'Update Amortisasi Biaya'
          this.getEditData()
          // console.log('this.editdata', this.editdata)
        } else {
          this.action = 'create'
          this.title = 'Create Amortisasi Biaya'
          // this.tempInput.f2digitreg = this.user.user_branch
        }
      }
    },
    nextFocus(to) {
      // const input = this.$refs.rekening.$el.querySelector('input')
      // input.click()
      // this.$refs.rekening.$el.blur()
      // console.log('to', to)
      setTimeout(() => {
        this.$refs[to].$el.focus()
      }, 200)
    },
    //   this.componentKey += 1
    //   var resmasterBarang = await lou.customUrlGet2(
    //     'transaksi/akuntansi/amortisasi/barang',
    //   )
    //   var resmasterCustomer = await lou.customUrlGet2(
    //     'transaksi/akuntansi/amortisasi/customer',
    //   )
    //   var resmasterEmployee = await lou.customUrlGet2(
    //     'transaksi/akuntansi/amortisasi/employee',
    //   )
    //   if (resmasterCustomer) {
    //     this.masterCustomer = []
    //     resmasterCustomer.data.forEach((element) => {
    //       var nobj = {
    //         ...element,
    //         label: element.nama_perusahaan + ' - ' + element.nama_kontak,
    //       }
    //       this.masterCustomer.push(nobj)
    //     })
    //     // console.log('this.masterRekening', this.masterRekening)
    //   }
    //   if (resmasterEmployee) {
    //     this.masterEmployee = []
    //     resmasterEmployee.data.forEach((element) => {
    //       var nobj = {
    //         ...element,
    //         label: element.nama + ' - ' + element.hp,
    //       }
    //       this.masterEmployee.push(nobj)
    //     })
    //     // console.log('this.masterRekening', this.masterRekening)
    //   }
    //   if (resmasterBarang) {
    //     this.masterBarang = []
    //     resmasterBarang.data.forEach((element) => {
    //       var nobj = {
    //         ...element,
    //         label: element.kode + ' - ' + element.nama + ' - ' + element.harga,
    //       }
    //       this.masterBarang.push(nobj)
    //     })
    //     // console.log('this.masterRekening', this.masterRekening)
    //   }
    //   // this.rowData.push({
    //   //   uuid: 'totaldarisemuanya',
    //   //   rekening: '',
    //   //   namarek: '',
    //   //   keterangan: 'Total',
    //   //   debet: 0,
    //   //   kredit: 0,
    //   // })
    // },
    async handleSubmit() {
      // console.log('this.rowData', this.rowData)
      if (this.input.tgl_perolehan !== null && this.input.tgl_perolehan !== '') {
        var fd = this.input
        // console.log('fd', fd)
        // console.log('this.editdata', this.editdata)
        fd.tgl_perolehan = fd.tgl_perolehan.format('YYYY-MM-DD')
        var res = null
        if (this.action === 'create') {
          delete fd.id
          res = await lou.customUrlPost2('transaksi/akuntansi/amortisasi', fd)
        } else {
          res = await lou.customUrlPut2('transaksi/akuntansi/amortisasi', fd)
        }
        if (res) {
          // this.editdata = res.data
          this.resetForm(true)
          // this.getEditData()
          // this.getAllData()
        }
        // if (this.input.order_id !== null && this.input.order_id !== '') {
        // } else {
        //   lou.shownotif('Peringatan!', 'Tidak ada data Order ID.', 'error')
        // }
        //   },
        //   onCancel: () => {
        //     // console.log('Cancel')
        //     return false
        //   },
        // })
      } else {
        lou.shownotif('Peringatan!', 'Tidak ada data yang diinput.', 'error')
      }
    },

    resetForm(back = false) {
      // this.editdata = {}
      // this.action = 'create'
      this.input = {
        nama: '',
        tgl_perolehan: moment(),
        harga_perolehan: '',
        lama_penyusutan: '',
        nilai_residu: '',
        catatan: '',
        rekening_kas_bank: '',
        rekening_aktiva: '',
        rekening_amortisasi: '',
        rekening_biaya: '',
      }
      // this.rowData = []
      if (back) {
        localStorage.removeItem('projectatransaksiamortisasibiaya')
        this.$parent.active = 0
        // this.$router.push({ path: '/projecta/transaksi/orderpenjualan' })
      }
    },
    getEditData() {
      // this.action = 'update'
      this.input = {
        id: this.editdata.id,
        tgl_perolehan: moment(this.editdata.tgl_perolehan, 'YYYY-MM-DD'),
        nama: this.editdata.nama,
        harga_perolehan: this.editdata.harga_perolehan,
        lama_penyusutan: this.editdata.lama_penyusutan,
        nilai_residu: this.editdata.nilai_residu,
        catatan: this.editdata.catatan,
        rekening_kas_bank: this.editdata.rekening_kas_bank,
        rekening_aktiva: this.editdata.rekening_aktiva,
        rekening_amortisasi: this.editdata.rekening_amortisasi,
        rekening_biaya: this.editdata.rekening_biaya,
      }
      console.log('moment(this.editdata.tgl_perolehan).format("DD-MM-YYYY")', moment(this.editdata.tgl_perolehan).format('DD-MM-YYYY'))
      // console.log('this.rowData1', this.rowData1)
      // var tempdata1 = this.$g.clone(this.editdata.details)
      // tempdata1.forEach((element) => {
      //   var dfasdf = {
      //     ...element,
      //   }
      //   this.rowData1.push(dfasdf)
      // })
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    back() {
      this.$parent.active = 0
      // this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped></style>
